// i18next-extract-mark-ns-start page-index

import Home from 'components/Home'
import { graphql } from 'gatsby'

export default Home

export const query = graphql`
query getHomePageQuery($language: String!) {
  pageData: allMdx(filter: {fields: {slug: {eq: "/"}}}) {
    nodes {
      body
      id
      internal {
        contentFilePath
      }
      headings {
        depth
        value
      }
      fields {
        slug
      }
      frontmatter {
        title
        logoFileName
        subtitle
        description
        enableStarUsBanner
        groupsToShow
        showCTA
        seoTitle
        seoDescription
        seoimage
      }
      parent {
        ... on GoogleDocs {
          id
          images {
            name
            publicURL
            childImageSharp {
              gatsbyImageData
            }
          }
          images2: images {
            name
            publicURL
            childImageSharp {
              gatsbyImageData(width: 2000, quality: 50, webpOptions: { quality: 70 }, breakpoints: [760])
            }
          }
          downloadedFiles: downloadedFiles {
            id
            name
            fileContent
            absolutePath
          }
        }
      }
    }
  }
  locales: allLocale(
    filter: {language: {eq: $language}, ns: {in: ["page-index", "common"]}}
  ) {
    edges {
      node {
        ns
        data
        language
      }
    }
  }
}

`
